export default {
  colors: {
    text: "#333",
    headerBackground: "#8a407f",
    headerText: "rgba(15, 14, 18, 1)",
    paperBackgroundColor: "#ffffff0d",
    paperHoverBackgroundColor: "#1b1b1b",
    paperHeadingColor: "#424141",
    paperHoverHeadingColor: "#f7f7f7",
    paperBorderColor: "transparent",
    articleBackgroundColor: "#ffffff80",
    paperDescriptionColor: "#8c8c8cde",
    collectionBackgroundColor: "#ecebeb80",
    collectionArticleBackgroundColor: "#ffffffa6",
    collectionArticleHoverBackgroundColor: "#1b1b1b",
    collectionArticleHoverTextColor: "#fbfbfb",
    collectionHeading: "#333333",
    collectionDescription: "#3333339d",
    breadcrumbLinkTextColor: "#999999",
    breadcrumbHoverLinkTextColor: "#777777",
    breadcrumbTextColor: "#333333",
    articleTextColor: "#565867",
    articleDescriptionColor: "#8F919D",
    background: "#eeeeee",
    primary: "#639",
    secondary: "#ff6347",
    muted: "#8f919d",
    searchTextColor: "#333333fd",
    searchTextFocusColor: "#333333",
    searchTextPlaceholderColor: "#777777",
    searchTextFocusPlaceholderColor: "black",
    comboboxColor: "black",
    iconColor: "#555",
    logoColor: "white",
    footerTextColor: "#a8b6c2",
    footerTextHoverColor: "#a8b6c2",
  },
  radii: [0, 2, 4, 8],
  fonts: {
    body: "system-ui, sans-serif",
    heading: "system-ui, sans-serif",
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    small: 1.2,
    body: 1.4,
    heading: 1.125,
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  buttons: {
    primary: {
      // you can reference other values defined in the theme
      color: "white",
      bg: "primary",
    },
    secondary: {
      color: "text",
      bg: "secondary",
    },
  },
  /* Styles for MDX */
  styles: {
    // the keys used here reference elements in MDX
    h1: {
      // the style object for each element
      // can reference other values in the theme
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      marginTop: 0,
      marginBottom: 3,
    },
    a: {
      color: "#bb41da",
      ":hover, :focus": {
        color: "#ffffff",
      },
    },
    blockquote: {
      marginLeft: "1.75rem",
    },
    root: {
      button: {
        background: "#8f4fd8",
        border: "0",
        color: "#fff",
        borderRadius: "4px",
        padding: ".25em 1.27em 0.1em",
        margin: ".5em 0",
        fontWeight: "400",
        lineHeight: "2",
        userSelect: "none",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        letterSpacing: "1px",
        textTransform: "uppercase",
        fontSize: ".9rem",
      },
    },
    // more styles can be added as needed
  },
}
